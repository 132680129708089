// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commented-input {
  border-bottom: 1px solid var(--lightGrayColor);
  padding-bottom: 20px;
}
.commented-input .form input[type=submit] {
  background-color: var(--orangeColor);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/common/add-comments/styles.scss"],"names":[],"mappings":"AAAA;EACE,8CAAA;EACA,oBAAA;AACF;AACM;EACI,oCAAA;EACA,WAAA;AACV","sourcesContent":[".commented-input{\n  border-bottom: 1px solid var(--lightGrayColor);\n  padding-bottom: 20px;\n  .form{\n      input[type=submit]{\n          background-color: var(--orangeColor);\n          color: #fff;\n      }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
