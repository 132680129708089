import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { CampaignProps } from "types/users/Campaign";
import { mediaTypes } from "./dummyData";
import { Bussiness } from "types/admin/bussinessList";
import { campaignService } from "services";

type CampaignState = CampaignProps.NewCampaignProps & {
  campaignFormId: number;
  campaignTypes: any[];
  mediumTypes: any[];
  supportTypes: any[];
  deliverables: any[];
  tones: any[];
  languages: any[];
  manner: any[];
  emotionalContext: any[];
  mediaTypes: any[];
  wordCounts: any[];
  campaignDetail: any;
  pressReleaseDetail: any;
  textLanguages: any[];
  aiHeadlines: any[];
  isLoading: boolean;
  uploadedImg: CampaignProps.ImageProps | null;
  isWordLoading: boolean;
  contentFile: any;
};

export const basicDetailState: CampaignProps.BasicDetails = {
  campaignName: '',
  subCampaignName: '',
  fromDate: '',
  toDate: '',
  objective: '',
  campaignId: '',
  brandId: null,
  specialistId: [],
  divisionId: [],
  userPromotionId: null,
  specialistType: ""
}
export const campaignTypeState: CampaignProps.CampaignTypes = {
  campaignType: '',
  mediumType: '',
  supportType: []
}
export const audienceDetailState: CampaignProps.AudienceDetails = {
  highestQualification: '',
  specializations: '',
  yearsOfExperience: '',
  interestAreas: '',
  subSpecializations: ''
}
export const budgetState: CampaignProps.Budget = {
  campaignBudget: '',
  costContact: '',
  budgetDescription: ''
}
export const contentDetailState: CampaignProps.ContentDetails = {
  // campaignContent: 'Ageing comes with many new challenges, like grey hair, loosened skin, and hair loss. These changes are easy to prepare for, as these external changes can be easily observed and tackled. However, ageing is not just subjected to externally visible changes but also affects the human body internally. One such ageing barrier is losing bone density as a person grows up. This further results in making bones weaker and bodies more fragile than ever. It makes a human body more susceptible to injuries and accidents.',
  campaignContent: '',
  mediaLink: '',
  croppedMediaLink: '',
  mediaType: '',
  dimension: '',
  isContentAmplification: true,
  isContentGeneration: false,
  headline: '',
  language: null,
  customHeadline: ''
}
export const operationalDetailState: CampaignProps.OperationalDetails = {
  emotionalContext: '',
  language: '',
  manner: '',
  tone: [],
  wordCount: null
}
export const releaseDetailState: CampaignProps.ReleaseDetails = {
  divisionName: '',
  type: '',
  persons: []
}
export const targetingState: CampaignProps.Targeting = {
  exclusion: '',
  inclusion: '',
  noOfParticipants: '',
  city: '',
  regions: ''
}

export const deliverableState: CampaignProps.Deliverables = {
  deliverable: ''
}

const initialState: CampaignState = {
  campaignFormId: 1,
  campaignTypes: [],
  mediumTypes: [],
  supportTypes: [],
  deliverables: [],
  tones: [],
  languages: [],
  manner: [],
  emotionalContext: [],
  wordCounts: [],
  basicDetails: basicDetailState,
  campaignType: campaignTypeState,
  audienceDetails: audienceDetailState,
  budget: budgetState,
  contentDetails: contentDetailState,
  operationalDetails: operationalDetailState,
  releaseDetails: releaseDetailState,
  targeting: targetingState,
  deliverable: deliverableState,
  mediaTypes: mediaTypes,
  campaignDetail: null,
  pressReleaseDetail: null,
  textLanguages: [],
  aiHeadlines: [],
  isLoading: false,
  uploadedImg: null,
  isWordLoading: false,
  contentFile: null
};

export const getCampaignTypeStore = createAsyncThunk(
  "auth/getCampaignTypeStore",
  async (param: Bussiness.ParamType) => {
    return await campaignService.getCampaignType(param);
  }
);
export const getCampaignMediumStore = createAsyncThunk(
  "auth/getCampaignMediumStore",
  async (param: Bussiness.ParamType) => {
    return await campaignService.getCampaignMedium(param);
  }
);
export const getCampaignSupportStore = createAsyncThunk(
  "auth/getCampaignSupportStore",
  async (param: Bussiness.ParamType) => {
    return await campaignService.getCampaignSupport(param);
  }
);
export const getWordCountRangeStore = createAsyncThunk(
  "auth/getWordCountRangeStore",
  async (param: Bussiness.ParamType) => {
    return await campaignService.getWordCountRange(param);
  }
);
export const getLanguageStore = createAsyncThunk(
  "auth/getLanguageStore",
  async (param: Bussiness.ParamType) => {
    return await campaignService.getLanguage(param);
  }
);
export const getAIHeadlineStore = createAsyncThunk(
  "auth/getAIHeadlineStore",
  async (param: CampaignProps.AIHeadlineParam) => {
    return await campaignService.getAIHeadline(param);
  }
);

export const campaignOprationalSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setCampaignDetail: (state, action: PayloadAction<any>) => {
      state.campaignDetail = action.payload?.data;
    },
    setPressReleaseDetail: (state, action: PayloadAction<any>) => {
      state.pressReleaseDetail = action.payload?.data;
    },
    setCampaignState: (state, action: PayloadAction<any>) => {
      const { campaignTypes, mediumTypes, supportTypes } = action.payload;
      state.campaignTypes = campaignTypes;
      state.mediumTypes = mediumTypes;
      state.supportTypes = supportTypes;
    },
    updateAIHeadingState: (state, action: PayloadAction<any>) => {
      state.aiHeadlines = action.payload?.data;
    },
    updateTextLanguageState: (state, action: PayloadAction<any>) => {
      state.textLanguages = action.payload?.data;
    },
    updateCampaignTypesState: (state, action: PayloadAction<any>) => {
      state.campaignTypes = action.payload?.campaignTypes;
    },
    updateMediumTypesState: (state, action: PayloadAction<any>) => {
      state.mediumTypes = action.payload?.mediumTypes;
    },
    updateSupportTypesState: (state, action: PayloadAction<any>) => {
      state.supportTypes = action.payload?.supportTypes;
    },
    setDeliverablesState: (state, action: PayloadAction<any>) => {
      state.deliverables = action.payload?.deliverables;
    },
    setMediaTypesState: (state, action: PayloadAction<any>) => {
      state.mediaTypes = action.payload?.mediaTypes;
    },
    setTonesState: (state, action: PayloadAction<any>) => {
      state.tones = action.payload?.tones;
    },
    setLanguagesState: (state, action: PayloadAction<any>) => {
      state.languages = action.payload?.languages;
    },
    setMannerState: (state, action: PayloadAction<any>) => {
      state.manner = action.payload?.manner;
    },
    setEmotionalContextState: (state, action: PayloadAction<any>) => {
      state.emotionalContext = action.payload?.emotionalContext;
    },
    updateContentFile: (state, action: PayloadAction<any>) => {
      state.contentFile = action.payload?.data;
    },
    setCampaignFormIdState: (state, action: PayloadAction<any>) => {
      const { campaignFormId } = action.payload;
      state.campaignFormId = campaignFormId;
    },
    setDeliverableState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.deliverable = action.payload?.deliverable;
    },
    setBasicDetailsState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.basicDetails = action.payload?.basicDetails;
    },
    setCampaignTypeState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.campaignType = action.payload?.campaignType;
    },
    setAudienceDetailsState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.audienceDetails = action.payload?.audienceDetails;
    },
    setBudgetState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.budget = action.payload?.budget;
    },
    setContentDetailsState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.contentDetails = action.payload?.contentDetails;
    },
    setOperationalDetailsState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.operationalDetails = action.payload?.operationalDetails;
    },
    setReleaseDetailsState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.releaseDetails = action.payload?.releaseDetails;
    },
    setTargetingState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.targeting = action.payload?.targeting;
    },
    setUploadedImgState: (state, action: PayloadAction<any>) => {
      state.uploadedImg = action.payload?.data;
    },
    resetCampaignOprationState: (state) => {
      state.campaignTypes = [];
      state.mediumTypes = [];
      state.supportTypes = [];
      state.deliverables = [];
      state.tones = [];
      state.languages = [];
      state.manner = [];
      state.emotionalContext = [];
      state.wordCounts = [];
      state.campaignFormId = 1;
      state.basicDetails = basicDetailState;
      state.campaignType = campaignTypeState;
      state.audienceDetails = audienceDetailState;
      state.budget = budgetState;
      state.contentDetails = contentDetailState;
      state.operationalDetails = operationalDetailState;
      state.releaseDetails = releaseDetailState;
      state.targeting = targetingState;
      state.deliverable = deliverableState;
      state.mediaTypes = mediaTypes;
      state.textLanguages = [];
      state.aiHeadlines = [];
      state.campaignDetail = null;
      state.pressReleaseDetail = null;
      state.uploadedImg = null;
      state.contentFile = null;
    },
    resetCampaignFormState: (state) => {
      state.tones = [];
      state.languages = [];
      state.manner = [];
      state.emotionalContext = [];
      state.wordCounts = [];
      state.campaignFormId = 1;
      state.basicDetails = basicDetailState;
      state.campaignType = campaignTypeState;
      state.audienceDetails = audienceDetailState;
      state.budget = budgetState;
      state.contentDetails = contentDetailState;
      state.operationalDetails = operationalDetailState;
      state.releaseDetails = releaseDetailState;
      state.targeting = targetingState;
      state.deliverable = deliverableState;
      state.mediaTypes = mediaTypes;
      state.textLanguages = [];
      state.aiHeadlines = [];
      state.campaignDetail = null;
      state.pressReleaseDetail = null;
      state.uploadedImg = null;
      state.contentFile = null;
    },
    restartContentDetailsState: (state, action: PayloadAction<CampaignProps.NewCampaignProps | null>) => {
      state.contentDetails = contentDetailState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignTypeStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.campaignTypes = data?.map((item: any) => {item.isChecked = false; return item;});
      })
    builder
      .addCase(getCampaignMediumStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.mediumTypes = data?.map((item: any) => {item.isChecked = false; return item;});
      })
    builder
      .addCase(getCampaignSupportStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.supportTypes = data?.map((item: any) => {item.isChecked = false; return item;});
      })   
    builder
      .addCase(getWordCountRangeStore.pending, (state, action) => {
        state.isWordLoading = true;
      })
      .addCase(getWordCountRangeStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        let wordCounts: any[] = [];
        data?.forEach((item: any) => {
          if(item?.status){
            wordCounts.push({value: item?.wordCountRangeId, label: `${item?.min || ''} - ${item?.max || ''}`})
          }
        });
        state.wordCounts = wordCounts;
        state.isWordLoading = false;
      }).addCase(getWordCountRangeStore.rejected, (state, action) => {
        state.isWordLoading = false;
      })
    builder
      .addCase(getLanguageStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.textLanguages = data?.map((item: any) => {item.isChecked = false; return item;});
      })    
    builder
      .addCase(getAIHeadlineStore.pending, (state, action) => {
        state.aiHeadlines = [];
        state.isLoading = true;
      })
      .addCase(getAIHeadlineStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        const content = state?.contentDetails?.headline;
        state.aiHeadlines = [...data?.map((item: any) => ({isChecked: content == item, title: item})), {isChecked: false, title: "Other"}];
        state.isLoading = false;
      }).addCase(getAIHeadlineStore.rejected, (state, action) => {
        state.isLoading = false;
      })            
  },
});

export const { 
  setCampaignState,
  setDeliverablesState,
  updateCampaignTypesState,
  updateMediumTypesState,
  updateSupportTypesState,
  resetCampaignOprationState,
  resetCampaignFormState,
  setBasicDetailsState,
  setAudienceDetailsState,
  setBudgetState,
  setCampaignTypeState,
  setContentDetailsState,
  setOperationalDetailsState,
  setReleaseDetailsState,
  setTargetingState,
  setCampaignFormIdState,
  setDeliverableState,
  setTonesState,
  setEmotionalContextState,
  setLanguagesState,
  setMannerState,
  setMediaTypesState,
  setCampaignDetail,
  updateTextLanguageState,
  updateAIHeadingState,
  setPressReleaseDetail,
  setUploadedImgState,
  updateContentFile,
  restartContentDetailsState
} = campaignOprationalSlice.actions;

export default campaignOprationalSlice.reducer;
export const selectCampaignState = (state: RootState) => state.campaignOpr;
export const selectCampaignTypes = (state: RootState) => state.campaignOpr.campaignTypes;
export const selectMediumTypes = (state: RootState) => state.campaignOpr.mediumTypes;
export const selectSupportTypes = (state: RootState) => state.campaignOpr.supportTypes;
export const selectDeliverables = (state: RootState) => state.campaignOpr.deliverables;
export const selectTones = (state: RootState) => state.campaignOpr.tones;
export const selectLanguages = (state: RootState) => state.campaignOpr.languages;
export const selectManner = (state: RootState) => state.campaignOpr.manner;
export const selectEmotionalContext = (state: RootState) => state.campaignOpr.emotionalContext;
export const selectMediaTypes = (state: RootState) => state.campaignOpr.mediaTypes;
export const selectWordCounts = (state: RootState) => state.campaignOpr.wordCounts;
export const selectTextLanguage = (state: RootState) => state.campaignOpr.textLanguages;
export const selectAIHeadlines = (state: RootState) => state.campaignOpr.aiHeadlines;

export const selectBasicDetails = (state: RootState) => state.campaignOpr.basicDetails;
export const selectCampaignType = (state: RootState) => state.campaignOpr.campaignType;
export const selectAudienceDetails = (state: RootState) => state.campaignOpr.audienceDetails;
export const selectBudget = (state: RootState) => state.campaignOpr.budget;
export const selectContentDetails = (state: RootState) => state.campaignOpr.contentDetails;
export const selectOperationalDetails = (state: RootState) => state.campaignOpr.operationalDetails;
export const selectReleaseDetails = (state: RootState) => state.campaignOpr.releaseDetails;
export const selectTargeting = (state: RootState) => state.campaignOpr.targeting;
export const selectCampaignFormId = (state: RootState) => state.campaignOpr.campaignFormId;
export const selectDeliverable = (state: RootState) => state.campaignOpr.deliverable;
export const selectCampaignDetail = (state: RootState) => state.campaignOpr.campaignDetail;
export const selectPressReleaseDetail = (state: RootState) => state.campaignOpr.pressReleaseDetail;
export const selectIsLoadingForCampaignCreate = (state: RootState) => state.campaignOpr.isLoading;
export const selectUploadedImg = (state: RootState) => state.campaignOpr.uploadedImg;
export const selectIsWordLoading = (state: RootState) => state.campaignOpr.isWordLoading;
export const selectContentFile = (state: RootState) => state.campaignOpr.contentFile