// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submitpress {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.cardView {
  height: 8rem;
  padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: auto;
  line-height: 2rem;
  text-align: center;
  background-color: #00B0BA;
  color: white;
  font-weight: bold;
  border-radius: 0.5rem;
  margin: 2rem;
}
.cardView:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/manage-content/create-content/payment-redirect/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,0CAAA;AACJ;;AACE;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,iBAAA;EACA,qBAAA;EACA,YAAA;AAEJ;AADI;EACE,eAAA;AAGN","sourcesContent":[".submitpress{\n    display: grid;\n    grid-template-columns: auto auto auto auto;\n  }\n  .cardView{\n    height: 8rem;\n    padding: 1rem;\n    padding-top: 3rem;\n    padding-bottom: 3rem;\n    width: auto;\n    line-height: 2rem;\n    text-align: center;\n    background-color:#00B0BA;\n    color: white;\n    font-weight: bold;\n    border-radius: .5rem;\n    margin: 2rem;\n    &:hover{  \n      cursor: pointer;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
