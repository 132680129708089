import BaseService from "./Base";
import AuthService from "./AuthService";
import ToneService from "./common/master/admin/ToneService";
import LanguageService from "./common/master/admin/LanguageService";
import MannerService from "./common/master/admin/MannerService";
import EmotionContextService from "./common/master/admin/EmotionContextService";
import BussinessListService from "./admin/bussinessListService";
import CampaignService from "./client/CampaignService";
import NotificationService from "./common/NotificationService";
import UserService from "./admin/UserService";
import BrandService from "./common/master/BrandService";
import SpecialistService from "./common/master/SpecialistService";
import DivisionService from "./common/master/DivisionService";
import RolePermissionService from "./common/RolePermissionService";
import DashboardService from "./common/DashboardService";
import EventService from "./EventService";

const authService = new AuthService();
const baseService = new BaseService();
const toneService = new ToneService();
const languageService = new LanguageService();
const mannerService = new MannerService();
const emotionContextService = new EmotionContextService();
const bussinessListService = new BussinessListService();
const campaignService = new CampaignService();
const notificationService = new NotificationService();
const userService = new UserService();
const brandService = new BrandService();
const specialistService = new SpecialistService();
const divisionService = new DivisionService();
const rolePermissionService = new RolePermissionService();
const dashboardService = new DashboardService();
const eventService = new EventService();

export {
  authService,
  baseService,
  toneService,
  languageService,
  mannerService,
  emotionContextService,
  bussinessListService,
  campaignService,
  notificationService,
  userService,
  brandService,
  specialistService,
  divisionService,
  rolePermissionService,
  dashboardService,
  eventService
};
