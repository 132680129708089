import { RouteType } from "./config";
import { BusinessListIcon, HomeBold, ManageBold, Master, NewCampaignBold, UserList } from 'assets';

const userRoutes: RouteType[] = [
  {
    index: true,
    path: "/dashboard",
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      img: <img src={HomeBold} alt="Dashboard" />,
    },
  },
  {
    index: true,
    path: "/campaign/create",
    state: "campaign_create",
    sidebarProps: {
      displayText: "Create Campaign",
      img: <img src={NewCampaignBold} alt="Create Campaign" />,
    },
  },
  {
    index: true,
    path: "/campaigns",
    state: "campaign",
    sidebarProps: {
      displayText: "My Campaigns",
      img: <img src={NewCampaignBold} alt="Campaigns" />,
    },
  },
  {
    index: true,
    path: "/press-releases",
    state: "press_release",
    sidebarProps: {
      displayText: "Press Releases",
      img: <img src={NewCampaignBold} alt="Press Releases" />,
    },
  },
  // {
  //   index: true,
  //   path: "/real-time-analysis",
  //   state: "real-time-analysis",
  //   sidebarProps: {
  //     displayText: "Real-time Analysis",
  //     img: <img src={Analytics} alt="Analysis" />,
  //   },
  // },
  {
    index: true,
    path: "/account-settings",
    state: "profile",
    sidebarProps: {
      displayText: "Account Settings",
      img: <img src={ManageBold} alt="Setting" />,
    },
  },
  {
    index: true,
    path: "/master",
    state: "master",
    sidebarProps: {
      displayText: "Master",
      img: <img src={Master} alt="Master" />,
    },
  },
];

export const agencyRoutes: RouteType[] = [
  {
    index: true,
    path: "/dashboard",
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      img: <img src={HomeBold} alt="Dashboard" />,
    },
  },
  {
    index: true,
    path: "/campaign/create",
    state: "campaign_create",
    sidebarProps: {
      displayText: "Create Campaign",
      img: <img src={NewCampaignBold} alt="Create Campaign" />,
    },
  },
  {
    index: true,
    path: "/campaigns",
    state: "campaign",
    sidebarProps: {
      displayText: "My Campaigns",
      img: <img src={NewCampaignBold} alt="Campaigns" />,
    },
  },
  {
    index: true,
    path: "/press-releases",
    state: "press_release",
    sidebarProps: {
      displayText: "Press Releases",
      img: <img src={NewCampaignBold} alt="Press Releases" />,
    },
  },
  {
    index: true,
    path: "/clients",
    state: "clients",
    sidebarProps: {
      displayText: "Clients",
      img: <img src={NewCampaignBold} alt="Clients" />,
    },
  },
  // {
  //   index: true,
  //   path: "/real-time-analysis",
  //   state: "real-time-analysis",
  //   sidebarProps: {
  //     displayText: "Real-time Analysis",
  //     img: <img src={Analytics} alt="Analysis" />,
  //   },
  // },
  {
    index: true,
    path: "/account-settings",
    state: "profile",
    sidebarProps: {
      displayText: "Account Settings",
      img: <img src={ManageBold} alt="Setting" />,
    },
  },
  {
    index: true,
    path: "/master",
    state: "master",
    sidebarProps: {
      displayText: "Master",
      img: <img src={Master} alt="Master" />,
    },
  },
];
export const superAdminRoutes: RouteType[] = [
  {
    index: true,
    path: "/dashboard",
    // element: <Dashboard />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      img: <img src={HomeBold} alt="Admin dashboard" />,
    },
  },
  {
    index: true,
    path: "/business-list",
    state: "business_list",
    sidebarProps: {
      displayText: "Business List",
      img: <img src={BusinessListIcon} alt="Business List" />,
    },
  },
  {
    index: true,
    path: "/campaigns",
    state: "campaign",
    sidebarProps: {
      displayText: "My Campaigns",
      img: <img src={NewCampaignBold} alt="My Campaigns" />,
    },
  },
  {
    index: true,
    path: "/press-releases",
    state: "press_release",
    sidebarProps: {
      displayText: "Press Releases",
      img: <img src={NewCampaignBold} alt="Press Releases" />,
    },
  },
  {
    index: true,
    path: "/users",
    state: "user",
    sidebarProps: {
      displayText: "Users",
      img: <img src={UserList} alt="Users" />,
    },
  },
  // {
  //   index: true,
  //   path: "/user-access-tier",
  //   state: "user-access-tier",
  //   sidebarProps: {
  //     displayText: "User Access Tier",
  //     img: <img src={UserAccessIcon} alt="User Access Tier" />,
  //   },
  // },
  // {
  //   index: true,
  //   path: "/subscription-packages",
  //   state: "subscription-packages",
  //   sidebarProps: {
  //     displayText: "Subscription Packages",
  //     img: <img src={PackageIcon} alt="Subscription Packages" />,
  //   },
  // },
  {
    index: true,
    path: "/master",
    state: "master",
    sidebarProps: {
      displayText: "Master",
      img: <img src={Master} alt="Master" />,
    },
  },
];
export default userRoutes;
