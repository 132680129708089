// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  background-color: var(--bodyColor);
  height: 100vh;
  display: flex;
}
section .navlink {
  text-decoration: none;
  color: inherit;
}
section .right-area-client {
  width: calc(100vw - 100px);
}
@media (max-width: 1366px) {
  section .right-area-client {
    width: calc(100vw - 250px);
  }
}
section .right-area-admin {
  width: calc(100vw - 100px);
}
section .right-area-100 {
  width: 100vw;
}
section .right-area {
  overflow: hidden;
  height: 100%;
  background-color: var(--bodyColor);
  border-radius: 0 0 0 0;
}
section .right-area .main {
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 75px);
  overflow-y: auto;
  padding: 15px;
}
section .right-area .main-client {
  background: -webkit-linear-gradient(272deg, var(--lightOrangeColor), var(--lightTealColor) 50%);
  height: 100vh;
  background: linear-gradient(272deg, var(--lightOrangeColor), var(--lightTealColor) 50%);
}`, "",{"version":3,"sources":["webpack://./src/components/layout/MainLayout/styles.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,aAAA;EACA,aAAA;AACF;AAAE;EACE,qBAAA;EACA,cAAA;AAEJ;AAAE;EACE,0BAAA;AAEJ;AADI;EAFF;IAGI,0BAAA;EAIJ;AACF;AAFE;EACE,0BAAA;AAIJ;AAFE;EACE,YAAA;AAIJ;AAFE;EACE,gBAAA;EACA,YAAA;EACA,kCAAA;EACA,sBAAA;AAIJ;AAFI;EACE,WAAA;EACA,cAAA;EACA,0BAAA;EACA,gBAAA;EACA,aAAA;AAIN;AADI;EACE,+FAAA;EAKA,aAAA;EAEA,uFAAA;AAFN","sourcesContent":["section {\n  background-color: var(--bodyColor);\n  height: 100vh;\n  display: flex;\n  .navlink {\n    text-decoration: none;\n    color: inherit;\n  }\n  .right-area-client {\n    width: calc(100vw - 100px);\n    @media (max-width: 1366px) {\n      width: calc(100vw - 250px);\n    }\n  }\n  .right-area-admin {\n    width: calc(100vw - 100px);\n  }\n  .right-area-100 {\n    width: 100vw;\n  }\n  .right-area {\n    overflow: hidden;\n    height: 100%;\n    background-color: var(--bodyColor);\n    border-radius: 0 0 0 0;\n\n    .main {\n      width: 100%;\n      margin: 0 auto;\n      height: calc(100vh - 75px);\n      overflow-y: auto;\n      padding: 15px;\n    }\n\n    .main-client {\n      background: -webkit-linear-gradient(\n        272deg,\n        var(--lightOrangeColor),\n        var(--lightTealColor) 50%\n      );\n      height: calc(100vh);\n\n      background: linear-gradient(\n        272deg,\n        var(--lightOrangeColor),\n        var(--lightTealColor) 50%\n      );\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
